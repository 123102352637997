const easy = {
  identifier: 'easy',
  image: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/easy-banner.png',
  thumbnail: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/peter.jpg',
  title: 'easy rider',
  titleShort: 'easy',
  blurbs: [
    <p key={1}>Based on the movie, the motorcycle, and Peter Fonda's shirt.</p>
  ],
  photos: [
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/easy-flower.jpg',
    // thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-easy/easy-flower.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/peter.jpg',
    note: 'The shirt.',
    // thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-easy/peter.jpg',
    },
    {
    src: 'https://bikes-and-barbies.s3-us-west-2.amazonaws.com/chopper.jpg',
    note: 'The motorcycle.',
    // thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-easy/chopper.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er00.jpg',
    note: 'The bike.',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-easy/er00.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-still05.jpg',
    note: 'Frame ready for priming.',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-easy/er-still05.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-still.jpg',
    note: 'Attaching the top tube braze-ons.',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-easy/er-still.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-still02.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-easy/er-still02.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-still03.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-easy/er-still03.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-still04.jpg',
    note: 'Braze-ons.',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-easy/er-still04.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-primed.jpg',
    note: 'Frame primed and now ready for some color.',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-easy/er-primed.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-yellow-seat-tube.jpg',
    note: 'Starting with the seat tube, paint it yellow to match the shirt base color.',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-easy/er-yellow-seat-tube.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-masking.jpg',
    note: 'Here I apply the mask for the pink paint on the seat tube.',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-easy/er-masking.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-paint-pink.jpg',
    note: 'Color mixed in airbursh cup.',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-easy/er-paint-pink.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-pink.jpg',
    note: 'A pink flower emerging.',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-easy/er-pink.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-red-mask.jpg',
    note: 'Next up the mask is applied for the red paint.',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-easy/er-red-mask.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-mask2.jpg',
    note: 'Mask detail for red paint.',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-easy/er-mask2.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-red-pink.jpg',
    note: 'Some red flower details appear after applying the paint through the mask.',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-easy/er-red-pink.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-seat-detail.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-easy/er-seat-detail.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-seat-tube-color-full.jpg',
    note: 'The final version of the seat tube with all flower colors applied.',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-easy/er-seat-tube-color-full.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-seat-tube-color-full2.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-easy/er-seat-tube-color-full2.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-seat-tube-color3.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-easy/er-seat-tube-color3.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-flag.jpg',
    note: 'Next I work on the Stars and Stripes on the top and head tubes.',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-easy/er-flag.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-flag2.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-easy/er-flag2.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-flag-blue.jpg',
    note: 'I lay a base color of blue before painting the stars.',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-easy/er-flag-blue.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-star-mask.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-easy/er-star-mask.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-painting-flag.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-easy/er-painting-flag.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er10.jpeg',
    note: 'The completed flag.',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-easy/er10.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-bike-masked.jpg',
    note: 'Next up I mask the frame in order to lay down the chrome and black paints.',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-easy/er-bike-masked.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-chrome.jpg',
    note: 'Some chrome detail.',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-easy/er-chrome.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-chrome2.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-easy/er-chrome2.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-easy-mask.jpg',
    note: 'And now the bike is masked to paint the black seat- and chainstays.',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-easy/er-easy-mask.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-mask-abstract.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-easy/er-mask-abstract.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-easy-painted.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-easy/er-easy-painted.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-mask-rings.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-easy/er-mask-rings.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-oh.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-easy/er-oh.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er-final-left.jpg',
    note: 'The finished frame.',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-easy/er-final-left.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/er14.jpeg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-easy/er14.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/helmet.jpg',
    note: 'And a helmet side project.',
    // thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-easy/helmet.jpg',
    },
  ]
}

export default easy;