import { useEffect } from 'react';

const Zoomed = ({src, alt, note, onClick, onPrevClicked, onNextClicked}) => {
    useEffect(() => {
        const handleScroll = (event) => {
            event.preventDefault();
        };

        window.addEventListener('wheel', handleScroll, { passive: false });

        return () => {
            window.removeEventListener('wheel', handleScroll);
        };
    }, []);
    return (
        <div className="zoomed-overlay">
            <button className={`prev-button + ${!onPrevClicked ? ' disabled' : ''}`} disabled={!onPrevClicked} onClick={onPrevClicked}>&lt;</button>
            <div className="zoomed-image-container" onClick={onClick}>
                <img className="zoomed-image" src={src} alt={alt} />
                {note && <p className="note">{note}</p>}
            </div>
            <button className={`next-button + ${!onNextClicked ? ' disabled' : ''}`} disabled={!onNextClicked} onClick={onNextClicked}>&gt;</button>
        </div>
    );
}

export default Zoomed;