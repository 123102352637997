const monster = {
  identifier: 'monster',
  thumbnail: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/Hendo13.jpg',
  image: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster-banner.png',
  title: 'monster',
  photos: [
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/Hendo13.jpg',
    // thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-monster/Hendo13.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00001.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-monster/monster00001.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00002.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-monster/monster00002.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00003.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-monster/monster00003.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00004.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-monster/monster00004.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00005.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-monster/monster00005.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00006.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-monster/monster00006.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00007.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-monster/monster00007.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00008.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-monster/monster00008.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00009.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-monster/monster00009.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00010.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-monster/monster00010.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00011.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-monster/monster00011.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00012.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-monster/monster00012.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00013.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-monster/monster00013.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00014.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-monster/monster00014.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00015.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-monster/monster00015.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00016.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-monster/monster00016.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00017.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-monster/monster00017.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00018.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-monster/monster00018.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00019.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-monster/monster00019.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00020.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-monster/monster00020.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00021.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-monster/monster00021.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00022.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-monster/monster00022.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00023.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-monster/monster00023.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00024.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-monster/monster00024.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00025.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-monster/monster00025.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00026.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-monster/monster00026.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00027.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-monster/monster00027.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00028.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-monster/monster00028.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00029.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-monster/monster00029.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00030.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-monster/monster00030.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00031.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-monster/monster00031.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00032.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-monster/monster00032.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00033.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-monster/monster00033.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/monster00034.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-monster/monster00034.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/hendo00001.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-monster/hendo00001.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/hendo00002.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-monster/hendo00002.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/hendo00003.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-monster/hendo00003.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/hendo00004.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-monster/hendo00004.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/hendo00005.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-monster/hendo00005.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/hendo00006.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-monster/hendo00006.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/hendo00007.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-monster/hendo00007.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/hendo00008.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-monster/hendo00008.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/hendo00009.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-monster/hendo00009.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/hendo00010.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-monster/hendo00010.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/hendo00011.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-monster/hendo00011.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/hendo00012.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-monster/hendo00012.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/hendo00013.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-monster/hendo00013.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/hendo00014.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-monster/hendo00014.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/hendo00015.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-monster/hendo00015.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/hendo00016.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-monster/hendo00016.jpg',
    },
  ]
};

export default monster;