import React from 'react';
import {useLocation} from 'react-router-dom';
import {useContent} from './contexts/content';

// let kodak = require('./kodak.png');

const Painting = () => {
    const location = useLocation();
    const {pathname = ''} = location;
    const identifier = pathname.replace('/', '');
    const {getContent} = useContent();

    if(!identifier) {
        return null;
    }

    const {title, image, attributes} = getContent(identifier);

    return (
        <div className="painting">
            <div className="slide">
                <div className="image-container">
                    {/* <div className="image-shadow" /> */}
                    <img src={image} alt="" />
                    {/* <img src={kodak} alt="" className="kodak" /> */}
                    <h1>{title}</h1>
                {attributes && attributes.length && (
                    <ul className="typewritten">
                        {attributes.map((a, i) => (<li key={`li_${i}`}>{a}</li>))}
                    </ul>
                )}
                </div>
            </div>
        </div>
    );
}

export default Painting;