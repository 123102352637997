import logo from './img/rad.png';

const Footer = () => {
  return (
    <div className="footer-container">
    <div className="footer-section">
        <div className="footer-text-container">
            <p className="footer-text designed">Website designed & built by Mark Henderson <a href='https://bikesandbarbies.com'>[aka Bikes & Barbies]</a></p>
            <p className="footer-text copyright">&copy;1966-2025</p>
            <p className="footer-text name">Bikes &amp; Barbies</p>
            <p className="footer-text address">Errington BC</p>
            <p className="footer-text phone">P: <span className="phone">(604) 315-2715</span></p>
            <p className="footer-text email">E: <a href='mailto:mark@bikesandbarbies.com?Subject=Hello'>mark@bikesandbarbies.com</a></p>
            <div className="footer-logo">
                <em>Powered with </em>   
                <img src={logo} className="app-logo footer-img" alt="logo" />
                React
            </div>
        </div>
        <div className="footer-ken">
            <img src="https://s3-us-west-2.amazonaws.com/bikes-and-barbies/ken.png" alt="ken" />
        </div>
    </div>
</div>
  );
};

export default Footer;