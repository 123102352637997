// import {useState} from "react";
import { useMemo } from "react";
import {useWahoo} from "./contexts/wahoo";
import {useNavigate} from "react-router-dom";

const WorkoutListItem = ({ workout }) => {
    const navigate = useNavigate();
    const {connected, getWorkoutSummaryData} = useWahoo();
    const {
        id,
        // starts,
        // minutes,
        name,
        // plan_id,
        // workout_token,
        // workout_type_id,
        workout_summary,
        created_at,
        // updated_at,
    } = workout || {};

    const {
        caloriesAccum,
        distanceAccum,
        heartRateAvg,
        powerAvg,
        durationString
    } = getWorkoutSummaryData(workout_summary);

    const createdAt = useMemo(() => {
        const options = {
            weekday: "short",
            year: "numeric",
            month: "short",
            day: "numeric",
          };
        const date = new Date(created_at);
        return date.toLocaleDateString("en-US", options);
    }, [created_at]);

   const handleGetWorkout = async () => {
        navigate(`/wahoo/workout/${id}`);
    };

    if(!connected) {
        navigate('/wahoo');
        return null;
    }

    return (
        <tr className="workout" onClick={handleGetWorkout}>  
            <td className="created-at">{createdAt}</td>
            <td className="name">{name}</td>
            <td className="heart-rate-avg">{heartRateAvg}</td>
            <td className="power_avg">{powerAvg}</td>
            <td className="calories">{caloriesAccum}</td>
            <td className="distance">{distanceAccum}</td>
            <td className="duration">{durationString}</td>
            <td ><input type="checkbox" onClick={e => e?.stopPropagation()}/></td>
        </tr>
    )
};

export default WorkoutListItem;