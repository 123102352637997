import SpanningImage from "./SpanningImage";
import {useContent} from './contexts/content';

const About = () => {
    const {abouts} = useContent();

    return (
        <div className="abouts">
            <div className="abouts-section">
                <div className="hand-built">
                    <div className="image-container">
                        <img src="https://s3-us-west-2.amazonaws.com/bikes-and-barbies/hand-built.svg" alt="" />
                    </div>
                </div>
                <div className="about-sections">
                    {abouts.map((about, i) => {
                        const {paragraphs} = about || {};

                        const aboutParagraphs = paragraphs.map((paragraph, i) => {
                            return (<p key={i} dangerouslySetInnerHTML={{__html: paragraph}} />);
                        });

                    return (
                        <div key={i} className="about-section">
                            <div className="about-paragraphs">
                                {aboutParagraphs}
                            </div>
                        </div>) ;
                    })}
                </div>
            </div>
            {/* <SpanningImage src="https://s3-us-west-2.amazonaws.com/bikes-and-barbies/code.jpg" /> */}
        </div>
    );
};

export default About;