import {Route, Routes} from 'react-router-dom'

import {useContent} from './contexts/content';
import About from './About';
import Barbies from './Barbies';
import Bikes from './Bikes';
import Footer from './Footer';
import Home from "./Home";
import OtherArt from './OtherArt';
import Painting from './Painting';
import Bike from './Bike';
import Wahoo from './Wahoo';
import Stories from './Stories';
import WorkoutDetail from './WorkoutDetail';
import Workouts from './Workouts';
import StrengthProgress from './StrengthProgress';
import StrengthWorkouts from './StrengthWorkouts';

const AppRoutes = () => {
  const {paintings, bikes, stories, other} = useContent();
    return (
        <div id="content" className='content'>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/bikes" exact element={<Bikes />} />
          <Route path="/barbies" exact element={<Barbies />} />
          <Route path="/other" exact element={<OtherArt />} />
          <Route path="/about" exact element={<><About /><Footer /></>} />
          <Route path="/wahoo" exact element={<Wahoo />} />
          <Route path="/wahoo/workouts" exact element={<Workouts />} />
          <Route path="/wahoo/workout/:id" exact element={<WorkoutDetail />} />
          <Route path="/progress" exact element={<StrengthProgress />} />
          <Route path="/workouts" exact element={<StrengthWorkouts />} />
          {paintings.map(painting => <Route key={painting.identifier} path={`/${painting.identifier}`} exact Component={Painting} />)}
          {other.map(painting => <Route key={painting.identifier} path={`/${painting.identifier}`} exact Component={Painting} />)}
          {bikes.map(bike => <Route key={bike.identifier} path={`/${bike.identifier}`} exact Component={Bike} />)}
          {Object.keys(stories).map(key => <Route key={key} path={`/${key}`} exact Component={Stories} />)}
        </Routes>
        
      </div>
    )
}

export default AppRoutes;