const prick = {
  identifier: 'prick',
  image: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick-banner.png',
  thumbnail: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick01.png',
  title: 'the prick',
  titleShort: 'prick',
  thumbnailPosition: 'top',
  blurbs: [
    <p key={1}>Inspired by the 2009 Astana bicycle ridden by Lance Armstrong.</p>
  ],
  photos: [
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick01.png',
    // thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-prick/prick01.png',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00001.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-prick/prick00001.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00002.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-prick/prick00002.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00003.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-prick/prick00003.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00004.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-prick/prick00004.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00005.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-prick/prick00005.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00006.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-prick/prick00006.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00007.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-prick/prick00007.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00008.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-prick/prick00008.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00009.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-prick/prick00009.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00010.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-prick/prick00010.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00011.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-prick/prick00011.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00012.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-prick/prick00012.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00013.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-prick/prick00013.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00014.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-prick/prick00014.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00015.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-prick/prick00015.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00016.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-prick/prick00016.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00017.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-prick/prick00017.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00018.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-prick/prick00018.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00019.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-prick/prick00019.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00020.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-prick/prick00020.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00021.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-prick/prick00021.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00022.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-prick/prick00022.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00023.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-prick/prick00023.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00024.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-prick/prick00024.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00025.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-prick/prick00025.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00026.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-prick/prick00026.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00027.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-prick/prick00027.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00028.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-prick/prick00028.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00030.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-prick/prick00030.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00031.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-prick/prick00031.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00032.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-prick/prick00032.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00033.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-prick/prick00033.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick00034.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-prick/prick00034.jpg',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/prick-top.png',
    },
    {
    src: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/needles.jpg',
    thumb: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/thumbnails-prick/needles.jpg',
    },
  ]
};

export default prick;
