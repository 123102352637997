import {useEffect, useState, useRef} from "react";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import {Line} from 'react-chartjs-2';
  
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

const dateOptions = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
};

// const skipped = (ctx, value) => ctx.p0.skip || ctx.p1.skip ? value : undefined;
// const down = (ctx, value) => ctx.p0.parsed.y > ctx.p1.parsed.y ? value : undefined;

const StrengthProgress = () => {
    const [squatData, setSquatData] = useState();
    const [deadliftData, setDeadliftData] = useState();
    const [benchPressData, setBenchPressData] = useState();
    const [pressData, setPressData] = useState();
    const [dataset, setDataset] = useState();
    const maxY = useRef(0);

    useEffect(() => {
        fetch('https://bikesandbarbies.com/api/workouts/1/progression/1', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        })
        .then(async response => {
            const json = await response.json();
            
            setSquatData(json);
        }).catch(error => console.log(error));
        fetch('https://bikesandbarbies.com/api/workouts/1/progression/2', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        })
        .then(async response => {
            const json = await response.json();

            setBenchPressData(json);
        }).catch(error => console.log(error));
        
        fetch('https://bikesandbarbies.com/api/workouts/1/progression/3', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        })
        .then(async response => {
            const json = await response.json();

            setDeadliftData(json);
        }).catch(error => console.log(error));
        
        fetch('https://bikesandbarbies.com/api/workouts/1/progression/4', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        })
        .then(async response => {
            const json = await response.json();

            setPressData(json);
        }).catch(error => console.log(error));
    }, []);

    const getExerciseDataset = (id, label, borderColor, backgroundColor, sortedDates, data) => {
        const dataset = {
            id,
            label,
            data: Array(sortedDates?.length ?? 0).fill(),
            borderColor,
            backgroundColor
        }
        data?.forEach(({date, max_weight}) => {
            const index = sortedDates.indexOf(date);
            if(index === -1) return;
            dataset.data[index] = max_weight;
            maxY.current = (parseInt(max_weight, 10) > maxY.current ? parseInt(max_weight, 10) : maxY.current);
        });

        return dataset;
    };

    useEffect(() => {
        if(!squatData || !benchPressData || !deadliftData || !pressData) return;

        const workoutDates = new Set();
        squatData?.data?.forEach(({date}) => {
            workoutDates.add(date);
        });
        benchPressData?.data?.forEach(({date}) => {
            workoutDates.add(date);
        });
        deadliftData?.data?.forEach(({date}) => {
            workoutDates.add(date);
        });
        pressData?.data?.forEach(({date}) => {
            workoutDates.add(date);
        });
        const sortedDates = [...workoutDates].sort((a, b) => {
            const d1 = new Date(a);
            const d2 = new Date(b);
            return d1 - d2;
        });
        maxY.current = 0;

        const datasets = [
            getExerciseDataset(1, 'squats', 'red', 'pink', sortedDates, squatData?.data),
            getExerciseDataset(2, 'bench', 'blue', 'cyan', sortedDates, benchPressData?.data),
            getExerciseDataset(3, 'dead', 'black', 'gray', sortedDates, deadliftData?.data),
            getExerciseDataset(4, 'press', 'orange', 'brown', sortedDates, pressData?.data)
        ];

        setDataset({labels: sortedDates.map(d => {const d1 = new Date(d); return d1.toLocaleDateString('en-CA', dateOptions)}), datasets});

    }, [squatData, benchPressData, deadliftData, pressData]);
    
    return (
        <div className="strength-progress">
            {!dataset ? 'Loading...' : null}
            {dataset && <div className="strength"> 
                <Line 
                    options={{        
                        responsive: true,
                        spanGaps: true,
                        // segment: {
                        //     borderDash: ctx => skipped(ctx, [6, 6]),
                        // },                
                        maintainAspectRatio: false,
                        scales: {y: {min: 0, max: parseInt(1.1 * maxY.current)}}}}
                    data={dataset} />
            </div>}     
        </div>
    )
}
export default StrengthProgress;
