
import Grid from './Grid';
import Thumbnail from './Thumbnail';
import {useContent} from "./contexts/content";

const OtherArt = () => {
    const {other, excluded} = useContent();
    
    return (
        <div>
            <Grid>
                {other.filter(o => !excluded.includes(o.identifier)).map(o => <Thumbnail object={o} key={o.identifier} />)}
            </Grid>
        </div>
    );
};

export default OtherArt;
