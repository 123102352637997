import React, {useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useContent} from './contexts/content';

import SpanningImage from './SpanningImage';
import Zoomed from './Zoomed';

const Bike = () => {
    // const [zoomed, setZoomed] = useState();
    const [zoomedIndex, setZoomedIndex] = useState();
    const location = useLocation();
    const {pathname = ''} = location;
    const identifier = pathname.replace('/', '');
    const {getContent} = useContent();
    const {/*title, titleShort, image, thumbnail, blurbs, attributes, */image, photos} = getContent(identifier);
    const {src: zoomedSrc, alt: zoomedAlt, note: zoomedNote} = zoomedIndex >= 0 ? photos[zoomedIndex] : {};

    const [loadedImages, setLoadedImages] = useState([true, ...Array(photos.length - 1).fill(false)]);

    if(!identifier) {
        return null;
    }

    const handleImageLoad = (index) => {
        if (index < photos.length - 1) {
          setLoadedImages((prev) => {
            const newLoaded = [...prev];
            newLoaded[index + 1] = true;
            return newLoaded;
          });
        }
      };    

    const handleZoomClicked = () => {
        // setZoomed();
        setZoomedIndex();
    }

    const handlePrevClicked = () => {
        setZoomedIndex(prev => prev - 1);
        // const currentIndex = photos.findIndex(p => p.src === zoomedSrc);
        // const prevIndex = currentIndex === 0 ? photos.length - 1 : currentIndex - 1;
        // setZoomed(photos[prevIndex]);
    }

    const handleNextClicked = () => {
        // const currentIndex = photos.findIndex(p => p.src === zoomedSrc);
        // const nextIndex = currentIndex === photos.length - 1 ? 0 : currentIndex + 1;
        setZoomedIndex(prev => prev + 1);
    }

    return (
        <div className="bike">
            <img className="bike-banner" src={image} alt="" />
            <div className="photos">
                {photos.map(({src, note, alt= '', thumb}, i) => (
                    loadedImages[i] && (
                        <div key={i} onClick={() => setZoomedIndex(i)}>
                            <img className="bike-thumb" src={thumb || src} alt={alt} onLoad={() => handleImageLoad(i)} />
                        </div>
                    )
                ))}
                {(zoomedIndex >= 0) && <Zoomed 
                    src={zoomedSrc} 
                    alt={zoomedAlt} 
                    note={zoomedNote}
                    onPrevClicked={zoomedIndex > 0 ? handlePrevClicked : null}
                    onNextClicked={zoomedIndex < photos.length - 1 ? handleNextClicked : null} 
                    onClick={handleZoomClicked} />}
                {/* <h1>{title}</h1>
                {attributes && attributes.length && (
                    <ul>
                        {attributes.map((a, i) => (<li key={`li_${i}`}>{a}</li>))}
                    </ul>
                )} */}
                {/* {(description || []).map((d, i) => (<p key={`p_${i}`}>{d}</p>))} */}
            </div>
            {/* <img src={image} alt="" /> */}
        </div>
    );
}

export default Bike;